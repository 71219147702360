import {h} from 'preact';
import register from 'preact-custom-element';

import ComponentToggleSignal from '@/components/component-toggle-signal';
import Drawer, {DrawerPadding, type DrawerProps} from '@/components/Drawer';

export const startDrawer = new ComponentToggleSignal();
export const endDrawer = new ComponentToggleSignal();

register(
  ({children}: Pick<DrawerProps, 'children'>) =>
    h(Drawer, {controller: startDrawer}, children),
  'start-drawer'
);

register(({children}: Pick<DrawerProps, 'children'>) => {
  // Disable the end drawer to prevent overlap with the upcart app
  const upcartGlobal = window as unknown as Window & {
    upcartOpenCart?: () => void;
  };
  if (upcartGlobal.upcartOpenCart) {
    return null;
  }
  return h(
    Drawer,
    {
      controller: endDrawer,
      endDrawer: true,
      padding: DrawerPadding.smallTop, // slightly less padding around the cart
    },
    children
  );
}, 'end-drawer');
